import styled from "@mui/material/styles/styled";
import IconButton from "@mui/material/IconButton";
import CopyAll from "@mui/icons-material/CopyAll";
import { toast } from "react-hot-toast";

type CopyToClipboardButtonProps = {
	text: string
}

const StyledCopyToClipboardButton = styled(IconButton)`
	position: absolute;
	top: 0;
	right: 0;
  margin: 0.25rem 0;
  color: ${({ theme }) => theme.palette.primary.dark};

	&:hover {
		border-radius: 3px;
	}
`;

export const CopyToClipboardButton = ({ text }: CopyToClipboardButtonProps) => {
  const handleClick = () => {
    toast.success('Copied to clipboard');
    navigator.clipboard.writeText(text);
  };

  return (
    <>
      <StyledCopyToClipboardButton onClick={handleClick}>
        <CopyAll />
      </StyledCopyToClipboardButton>
    </>
  );
}