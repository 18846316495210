import styled from "@mui/material/styles/styled";
import { StyledContainer } from "./styled/Container";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import Box from "@mui/material/Box";
import { StyledItem } from "./styled/Item";
import { Button, Typography } from "@mui/material";
import { GradientBackground } from "./GradientBackground";
import SmallLogo from "../images/logo_small.png";
import { StyledTextField } from "./styled/TextField";
import React, { ChangeEvent, forwardRef, useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { postTgUsername } from "../utils/api";
import toast from "react-hot-toast";

const TEXTS = {
	title: 'Apply as a Project',
	description: "We're always on the lookout for high-value DeFi projects that are poised to make a mark in the industry. If you're aiming to establish a dedicated community, bring aboard top KOLs without hefty upfront payments, and save significant time on negotiations, you've come to the right spot. To contact us use the form on the right.",
}

const ApplyForAirdropContainer = styled(StyledContainer)`
	padding: 4rem 0;
	margin: 0 3rem;
	width: calc(100% - 6rem);
`;

const AirdropContent = styled(Box)`
	margin-top: 2rem;

	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	gap: 1rem;
`;

const ContentItem = styled(StyledItem)`
	flex: 2;
	height: unset;
	margin: 0;
	padding: 2rem 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex: unset;
		width: calc(100% - 2rem);
	}
`;

const AirdropItem = styled(StyledItem)`
	position: relative;
	height: unset;
	overflow: hidden;
	flex: 1;
	margin: 0;
	padding: 2rem 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex: unset;
		width: calc(100% - 2rem);
	}
`;

const AirdropItemContent = styled(Box)`
	position: relative;
	z-index: 1;

	display: flex;
	flex-direction: column;
`;

const ImageHolder = styled(Box)`
	  padding: 1rem 1rem;
    margin: auto;
`;

const tgPatternRegex = new RegExp(/^@[a-zA-Z0-9_]{1,256}$/gi);

export const ApplyForAirdropBlock = forwardRef((_, ref: React.Ref<HTMLDivElement>) => {
	const inputRef = useRef<HTMLInputElement>(null);
	const [inputError, setInputError] = useState(true);
	const { mutate, isSuccess, isPending } = useMutation({ mutationFn: postTgUsername });

	const buttonDisabled = inputError || isSuccess || isPending;
	const handleClick = () => {
		if (inputRef.current?.value) {
			mutate({ username: inputRef.current.value });
		}
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value) {
			const match = value.match(tgPatternRegex);
			setInputError(!match);
		}
	}

	useEffect(() => {
		if (isSuccess) {
			toast.success('Thank you for applying! We will contact you soon');
		}
	}, [isSuccess])

	return (
		<ApplyForAirdropContainer ref={ref}>
			<SecondaryTitle variant="h3">
				{TEXTS.title}
			</SecondaryTitle>
			<AirdropContent>
				<ContentItem>
					<Typography variant="body2" color="text.secondary">
						{TEXTS.description}
					</Typography>
				</ContentItem>
				<AirdropItem>
					<GradientBackground type="popup" />
					<AirdropItemContent>
						<ImageHolder>
						<img src={SmallLogo} alt="logo" style={{width: '100%'}} />
						</ImageHolder>
						<StyledTextField 
							size="small"
							variant="outlined"
							placeholder="Your tg handle starting with @"
							inputProps={{ ref: inputRef }}
							onChange={handleInputChange}
						/>
						<Button 
							variant="contained"
							disabled={buttonDisabled}
							onClick={handleClick}
						>
							Apply
						</Button>
					</AirdropItemContent>
				</AirdropItem>
			</AirdropContent>
		</ApplyForAirdropContainer>
	);
});