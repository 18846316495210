import toast from "react-hot-toast";
import { useAuth } from "../hooks/useAuth";
import { useEffect } from "react";

export const RequireAuth = ({ children }: { children: JSX.Element }) => {
	const auth = useAuth();

	useEffect(() => {
		if (auth && !auth.isAuthentificated) {
			toast.error("You are not allowed to see this page!\nYou will be redirected to main page soon");
			setTimeout(() => {
				auth.signout();
			}, 3000);
		}
	}, [auth]);

	if (!auth.isAuthentificated) {
		return null;
	}

	return children;
}