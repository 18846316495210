import styled from "@mui/material/styles/styled"
import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import TelegramIcon from "@mui/icons-material/Telegram";
import { ActionsBox } from "./styled/ActionsBox";
import { XIcon } from "./icons/x";
import { IconWrapper } from "./styled/IconWrapper";

const LinksContainer = styled(ActionsBox)`
	padding: 3rem 1rem;
	margin-top: auto;
`;

const StyledTypography = styled(Typography)`
	padding: 0 1rem;
	color: ${({ theme }) => theme.palette.primary.main};
	font-weight: 300;
	transition: color .3s;
`;

const StyledLink = styled(Link)`
	text-decoration: none;
	display: flex;
	justify-content: flex-start;
  align-items: center;
  border: 1px solid;
  height: 30px;
  border-radius: 5rem;
	position: relative;
	overflow: hidden;

	&::before {
		position: absolute;
  	top: 0;
  	right: 0;
  	bottom: 0;
  	left: 0;
  	z-index: -1;
  	content: '';
  	background: ${({ theme }) => theme.palette.primary.main};
  	transition: transform ease-out .2s, color .3s;
		transform-origin: right top;
  	transform: scale(0, 1);
	}

	&:hover::before {
  	transform-origin: left top;
  	transform: scale(1, 1);
	}

	&:hover, &:hover .MuiTypography-root.MuiTypography-body2 {
		color: black;
	}

`;
export const Links = () => {
	return (
		<LinksContainer>
			<StyledLink href="https://twitter.com/x2ecosystem" target="_blank" rel="noreferrer">
				<IconWrapper>
					<XIcon />
				</IconWrapper>
				<StyledTypography variant="body2">Twitter</StyledTypography>
			</StyledLink>
			<StyledLink href="https://t.me/x2ecosystem" target="_blank" rel="noreferrer">
				<IconWrapper>
					<TelegramIcon />
				</ IconWrapper>
				<StyledTypography variant="body2">Telegram</StyledTypography>
			</StyledLink>
		</LinksContainer>
	)
};