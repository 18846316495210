import { ChangeEvent, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { StyledContainer } from "./styled/Container";
import { postTweetLink } from "../utils/api";
import toast from "react-hot-toast";
import { StyledTextField } from "./styled/TextField";
import { StyledActionBox } from "./styled/ActionsBox";
import { useUpdateTokenStats } from "../hooks/useUpdateTokenStats";
import { SecondaryText } from "./styled/Titles";
import { StyledButton } from "./styled/Button";

const urlPatternRegex = new RegExp(/^https?:\/\/(www\.)?(?:x.com|twitter.com)\/[\w\s]{1,256}\/status\/[0-9]{1,256}(?:\?s=[0-9]+)?$/gi);

export const PostVerification = () => {
	const [link, setLink] = useState<string | null>(null);
	const [hasError, setHasError] = useState(false);
	const [enableStatsFetch, setEnableStatsFetch] = useState(false);
	const { mutate, isSuccess, isError, isPending, error } = useMutation({ mutationFn: postTweetLink });
	useUpdateTokenStats(enableStatsFetch);
	const handleClick = () => {
		if (link) {
			mutate({ link });
		}
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;

		if (value) {
			const match = value.match(urlPatternRegex);
			setHasError(!match);
			match && setLink(match[0]);
		}
	}

	useEffect(() => {
		if (isError) {
			// @ts-ignore 
			const message = error?.response?.data?.error;
			message && toast.error(message);
		}
	}, [error, isError]);

	useEffect(() => {
		if (isSuccess) {
			toast.success('Link successfully send for verification');
			setEnableStatsFetch(true);
		}
	}, [isSuccess]);

	const buttonDisabled = hasError || !link || isSuccess || isPending;

	return (
		<StyledContainer>
			<SecondaryText variant="h5">Once you've done with post creation paste post link in the field below and click Verify button</SecondaryText>
			<StyledActionBox>
				<StyledTextField
					error={hasError}
					helperText={hasError ? "Invalid link" : "Please paste tweet link here"}
					id="tweet-link-text-field"
					label="Tweet Link"
					color="info"
					onChange={handleInputChange}
				/>
				<StyledButton
					variant="contained"
					onClick={handleClick}
					disabled={buttonDisabled}	
				>
					Verify
				</StyledButton>
			</StyledActionBox>
		</StyledContainer>
	)
}