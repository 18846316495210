import { useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import { StyledContainer } from '../components/styled/Container';
import { FullHeightContainer } from "../components/styled/FullHeightContainer";
import { GradientBackground } from "../components/GradientBackground";
import { CarouselComponent } from "../components/Carousel";
import { WhitepaperBlock } from "../components/WhitepaperBlock";
import { ApplyForAirdropBlock } from "../components/ApplyForAirdropBlock";
import { TokenomicsBlock } from "../components/FAQBlock";
import { Footer } from "../components/Footer";
import { AboutUsBlock } from "../components/AboutUsBlock";
import { RefsProvider } from "../providers/refsProvider";
import { useRefs } from "../hooks/useRefs";

const CAROUSEL_PARAMS = {
	title: 'How to use',
	items: [
		{
			title: 'Sign in with your X',
		},
		{
			title: 'Post about the project and share the link',
		},
		{
			title: 'Claim earned $X2E right to your wallet',
		},
	]
}

const AboutUs = () => {
	const {setRefs} = useRefs();
	const featuresRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			features: featuresRef
		}));
	},
	[setRefs]);

	return (
		<FullHeightContainer>
			<AboutUsBlock />
			<CarouselComponent {...CAROUSEL_PARAMS} ref={featuresRef} />
		</FullHeightContainer>
	);
}

const WhitepaperPartners = () => {
	const {setRefs} = useRefs();
	const whitepaperRef = useRef<HTMLDivElement | null>(null);
	const applyForAirdropRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			whitepaper: whitepaperRef,
			applyForAirdrop: applyForAirdropRef
		}));
	},
	[setRefs]);

	return (
		<FullHeightContainer>
			<WhitepaperBlock ref={whitepaperRef}/>
			<ApplyForAirdropBlock ref={applyForAirdropRef}/>
		</FullHeightContainer>
	)
};

const Tokenomics = () => {
	const {setRefs} = useRefs();
	const faqRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		setRefs((prev) => ({
			...prev,
			faq: faqRef
		}));
	}, [setRefs]);

	return (
	<StyledContainer>
		<TokenomicsBlock ref={faqRef}/>
	</StyledContainer>
	)
}

const StyledBox = styled(Box)`
	position: relative;
`

export const MainPage = () => {
	return (
		<RefsProvider>
			<StyledBox>
				<StyledContainer>
					<AboutUs />
					<WhitepaperPartners />
					<Tokenomics />
				</StyledContainer >
				<GradientBackground type="secondary" />
				<Footer />
			</StyledBox>
		</RefsProvider>
	)
}