import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';
import createTheme from '@mui/material/styles/createTheme';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: { main: '#C6E600' },
    secondary: { main: '#1941E6' },
    info: { main: '#3E4666' },
    background: {
      default: '#040404',
      paper: '#090A0B'
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#BCBCBC'
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        color: '#212B36'
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '4rem',
          fontWeight: 700,
          textTransform: 'none',
          padding: '0.5rem 2rem',
        }
      }
    }
  },
  typography: {
    fontFamily: [
      'NunitoSans'
    ].join(',')
  }
});

const theme = responsiveFontSizes(darkTheme);
const queryClient = new QueryClient();

root.render(
  // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
