import Button from "@mui/material/Button";
import { SignInButton } from "./SignInButton";
import { ActionsBox } from "./styled/ActionsBox";
import { useRefs } from "../hooks/useRefs";

export const Actions: () => JSX.Element = () => {
	const {refs} = useRefs();
	const onClick = () => refs['faq']?.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<ActionsBox>
			<SignInButton text="Sign in with X account" />
			<Button color="primary" variant="outlined" onClick={onClick}>Learn more</Button>
		</ActionsBox>
	);
}