import { TaskContainer } from "./styled/TaskContainer"
import { AddressForm } from "./AddressForm"
import { TokenInfo } from "./TokenInfo"
import { SecondaryTitle } from "./styled/SecondaryTitle"
import Box from "@mui/material/Box"
import styled from "@mui/material/styles/styled"
import Button from "@mui/material/Button"
import { useNavigate } from "react-router-dom"

const ClaimRewardBox = styled(Box)`
	position: relative;
	z-index: 2;
	padding: 1rem;
`;

const TopSectionContainer = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;
`;

const StyledButton = styled(Button)`
	position: relative;
	z-index: 5;
	
	${({ theme }) => theme.breakpoints.down('md')} {
		margin: auto;
		flex-wrap: wrap-reverse;
	}
`;

export const ClaimReward = () => {
	const navigate = useNavigate();

	const onClick = () => {
		navigate('/');
	};

	return (
		<ClaimRewardBox>
			<TopSectionContainer>
			<SecondaryTitle variant="h4">You successfully finished the task</SecondaryTitle>
			<StyledButton variant="contained" onClick={onClick}>Go back to Main</StyledButton>
			</TopSectionContainer>
			<TaskContainer elevation={3}>
				<TokenInfo />
				<AddressForm />
			</TaskContainer>
		</ClaimRewardBox>
	);
}