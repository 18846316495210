import styled from "@mui/material/styles/styled";
import { StyledContainer } from "./Container";

export const SectionContainer = styled(StyledContainer)<{ centered?: number }>`
	width: 50%;
	padding: 2rem 1rem 2rem 1rem;
	margin: unset;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
	}

	${({ centered }) => centered && `
		align-self: center;
    text-align: center;
    justify-content: center;
    align-items: center;
	`}
`;