import CircularProgress from "@mui/material/CircularProgress";
import styled from "@mui/material/styles/styled";

import { useStats } from "../hooks/useStats";
import { AvailableTasks } from "../components/AvailableTasks";
import { ClaimReward } from "../components/ClaimReward";
import { FullHeightContainer } from "../components/styled/FullHeightContainer";
import { GradientBackground } from "../components/GradientBackground";
import { useUpdateTokenStats } from "../hooks/useUpdateTokenStats";
import Button from "@mui/material/Button";

const TasksPageContainer = styled(FullHeightContainer)`
	justify-content: center;
	align-items: center;

	${({ theme }) => theme.breakpoints.down('md')} {
		position: relative;
		min-height: 100vh;
	}
`;

export const TasksPage = () => {
	const { stats, loading } = useStats();
	useUpdateTokenStats(true);

	return (
		<TasksPageContainer>
			<GradientBackground />
			{loading 
				? <CircularProgress color="primary" /> 
				: !stats?.taskDone 
					? <AvailableTasks />
					: <ClaimReward />
			}
		</TasksPageContainer>
	);
}