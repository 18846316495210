import SvgIcon from "@mui/material/SvgIcon";

export const XIcon = () => (
	<SvgIcon>
		<svg
			stroke="none"
			viewBox="0 0 24 24"
			strokeWidth={1.5}
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.0718 9.33252L21.1791 0.111115H19.2579L12.2184 8.11793L6.59593 0.111115H0.111084L8.61335 12.2188L0.111084 21.8889H2.03236L9.46629 13.4334L15.404 21.8889H21.8889L13.0718 9.33252Z" />
		</svg>
	</SvgIcon>
);