import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";

export const PrimaryTitle = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.primary};
	padding: 1rem 0;
`;

export const PrimaryDarkTitle = styled(Typography)`
	display: flex;
	gap: 1rem;

	padding: 0.25rem 0;
	color: ${({ theme }) => theme.palette.primary.dark};
`;

export const SecondaryText = styled(Typography)`
	color: ${({ theme }) => theme.palette.text.secondary};
`;


export const PrimaryTitleWithPointer = styled(PrimaryTitle)`
  cursor: pointer;
  font-size: 1.25rem;

  ${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
    word-wrap: break-word;
	}
`;