import { useMemo, useState } from "react";
import { StatsContext } from "../contexts/StatsContext";
import { TokenStatsGetResponse as TokenStats } from "../../../shared/contracts/token.stats.get";

export const StatsProvider = ({ children }: { children: React.ReactNode }) => {
	const [stats, setStats] = useState<TokenStats>(null!);
	const [loading, setLoading] = useState<boolean>(true);
	const value = useMemo(() => ({ stats, setStats, loading, setLoading }), [loading, stats]);

	return (
		<StatsContext.Provider value={value}>{children}</StatsContext.Provider>
	);
};