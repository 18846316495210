import styled from "@mui/material/styles/styled";
import { StyledContainer } from "./styled/Container";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import React, { forwardRef } from "react";
import Box from "@mui/material/Box";
import { SignInButton } from "./SignInButton";
import { PrimaryTitle, PrimaryTitleWithPointer, SecondaryText } from "./styled/Titles";
import { StyledItem } from "./styled/Item";
import { GradientBackground } from "./GradientBackground";
import SmallLogo from "../images/logo_small.png";
import { CONTRACT, handleClick } from "../constants/contract";

const TokenomicsBlockContainer = styled(StyledContainer)`
	padding: 4rem 0;
	margin: 0 3rem;
	width: calc(100% - 6rem);
`;

const TokenomicsContentBlock = styled(Box)`
	display: flex;
	gap: 1rem;
	flex-wrap: wrap;
	margin-top: 2rem;
	width: 100%;
`;

const TokenomicsDescriptionBlock = styled(Box)`
	flex: 2;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex: 1;
	}
`;

const TokenomicsCallToActionBlock = styled(Box)`
	display: flex;
	flex-direction: column;
	align-items: stretch;
	gap: 1.5rem;
	padding: 0 0.5rem;
	flex: 1;
`;

const TokenomicsDescriptionText = styled(SecondaryText)`
	padding-right: 10%;
`;

const TokenomicsDescriptionItemsContainer = styled(Box)`
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
	gap: 1rem;

	margin-top: 1rem;
`;

const TokenomicsDescriptionItem = styled(StyledItem)`
	height: unset;
	flex: 1;
	position: relative;
	padding: 1rem 2rem;
	box-sizing: border-box;
	margin: 0;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-basis: 100%;
	}
`;

const TokenomicsDescriptionItemWithImage = styled(TokenomicsDescriptionItem)`
	display: flex;
	overflow: hidden;

	${({ theme }) => theme.breakpoints.down('md')} {
		min-height: 15rem;
	}
`;

const ImageHolder = styled(Box)`
	position: relative;
  margin: auto;
	z-index: 3;
`;

const SignInButtonHolder = styled(Box)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 -0.5rem;
	margin-top: auto;
`;

const ItemWithPercentAndTextContainer = styled(StyledItem)`
	display: flex;
	height: unset;
	width: unset;
	margin: 0;
  align-items: center;
  gap: 1rem;
	border-radius: 1rem;
  padding: 0.25rem 1rem;
	margin: 0 -0.5rem;
`;

const StyledPrimaryTitleWithPointer = styled(PrimaryTitleWithPointer)`
  max-width: 230px;
  word-wrap: break-word;
  font-size: 1rem;
  
  ${({theme}) => theme.breakpoints.down('md')} {
    max-width: 160px;
  }
`;

type ItemWithPercentAndTextProps = {
	percent: number;
	text: string;
}

const ItemWithPercentAndText = ({ percent, text }: ItemWithPercentAndTextProps) => {
	return (
		<ItemWithPercentAndTextContainer>
			<SecondaryTitle>
				{percent}%
			</SecondaryTitle>
			<PrimaryTitle>
				{text}
			</PrimaryTitle>
		</ItemWithPercentAndTextContainer>
	);
}

export const TokenomicsBlock = forwardRef((_, ref: React.Ref<HTMLDivElement>) => {
	return (
		<TokenomicsBlockContainer ref={ref}>
			<SecondaryTitle variant="h3">
				Tokenomics
			</SecondaryTitle>
			<TokenomicsContentBlock>
				<TokenomicsDescriptionBlock>
					<TokenomicsDescriptionText>
					$X2E is the only token of X to earn ecosystem, holders of which are eligible for the revenue share 
					</TokenomicsDescriptionText>
					<TokenomicsDescriptionItemsContainer>
						<TokenomicsDescriptionItem>
							<PrimaryTitle>
							Total supply: 500,000,000,000 $X2E
							</PrimaryTitle>
							<PrimaryTitle>
							Ticker: $X2E
							</PrimaryTitle>
							<StyledPrimaryTitleWithPointer onClick={handleClick}>
							Contract address: {CONTRACT}
							</StyledPrimaryTitleWithPointer>
						</TokenomicsDescriptionItem>
						<TokenomicsDescriptionItemWithImage>
							<GradientBackground type="popup" />
							<ImageHolder>
								<img src={SmallLogo} alt="logo" style={{width: '100%'}} />
							</ImageHolder>
						</TokenomicsDescriptionItemWithImage>
					</TokenomicsDescriptionItemsContainer>
				</TokenomicsDescriptionBlock>
				<TokenomicsCallToActionBlock>
					<ItemWithPercentAndText percent={4} text="Tax" />
					<ItemWithPercentAndText percent={90} text="Liquidity"/>
					<ItemWithPercentAndText percent={10} text="X2Earn rewards" />
					<SignInButtonHolder>
						<SignInButton text="Sign in with X account" />
					</ SignInButtonHolder>
				</TokenomicsCallToActionBlock>
			</TokenomicsContentBlock>
		</TokenomicsBlockContainer>
	)
});