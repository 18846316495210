import styled from "@mui/material/styles/styled";
import TextField from "@mui/material/TextField";

export const StyledTextField = styled(TextField)`
	margin: 0.5rem 0 2rem 0;
	flex: 1;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
		margin: 0.5rem 0;
	}

	& .MuiInputBase-input {
		background-color: #040404;
	}

	& .MuiOutlinedInput-notchedOutline {
		border-color: ${({ theme }) => theme.palette.primary.dark};
	}

	&:hover .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline, 
	&:focus-within .MuiInputBase-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline  {
		border-color: ${({ theme }) => theme.palette.primary.dark};
	}

	&:focus-within .MuiFormLabel-root.MuiInputLabel-root {
		color: ${({ theme }) => theme.palette.background.default};
		background-color: ${({ theme }) => theme.palette.primary.dark};
		padding: 0.1rem 0.5rem;
		border-radius: 1rem;
	}
`;