import { useMemo } from "react";

const setLocalStorageItem = (key: string, value: string) => {
	localStorage.setItem(key, value);
}
const getLocalStorageItem = (key: string) => {
	return localStorage.getItem(key);
}

const removeLocalStorageItem = (key: string) => {
	localStorage.removeItem(key);
}

export const useLocalStorage = () => {
	return useMemo(() => ({
		setLocalStorageItem,
		getLocalStorageItem,
		removeLocalStorageItem
	}), [])
}