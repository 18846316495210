import styled from "@mui/material/styles/styled";
import { StyledContainer } from "./styled/Container";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import { StyledItem } from "./styled/Item";
import Box from "@mui/material/Box";
import React, { forwardRef } from "react";
import WhitepaperImage from "../images/whitepaper.png";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import Link from "@mui/material/Link";
import WhitepaperText from '../images/whitepaper_text.png';

const TEXTS = {
	whitepaper: {
		title: "Whitepaper & Partners",
	}
};

const images = [
	{
		url: WhitepaperImage,
		title: 'WHITEPAPER',
		width: '100%',
	}
];

const WhitepaperContainer = styled(StyledContainer)`
	justify-content: center;
	align-items: center;
	padding: 4rem 3rem;
`;

const ContentBox = styled(Box)`
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: calc(50vh - 2rem);
	width: calc(100% - 2rem);
	margin-top: 2rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
	}
`;

const ContentItem = styled(StyledItem)`
	display: flex;
	justify-content: center;
	align-items: center;

	${({ theme }) => theme.breakpoints.down('md')} {
		margin: 0;
		width: calc(100% - 2rem);
	}
`;

const ImageBlock = styled(ContentItem)`
	height: calc(50vh - 2rem);
	overflow: hidden;
`;

const PartnersBlock = styled(ContentItem)`
	height: calc(30vh - 2rem);
`;

const ImageButton = styled(ButtonBase)(({ theme }) => ({
	position: 'relative',
	height: '105%',
	width: '105%',
	'&:hover, &.Mui-focusVisible': {
		zIndex: 1,
		'& .MuiImageBackdrop-root': {
			opacity: 0.35,
		},
		'& .MuiImageMarked-root': {
			opacity: 0,
		},
		'& .MuiTypography-root': {
		},
	},
}));

const ImageSrc = styled('span')({
	position: 'absolute',
	left: '-1rem',
	right: '-1rem',
	top: '-1rem',
	bottom: '-1rem',
	backgroundSize: 'cover',
	backgroundPosition: '35%',
});

const Image = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	fontSize: '1.5rem',
	fontWeight: 'bold',
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
	position: 'absolute',
	left: '-1rem',
	right: '-1rem',
	top: '-1rem',
	bottom: '-1rem',
	backgroundColor: theme.palette.common.black,
	opacity: 0.8,
	transition: theme.transitions.create('opacity'),
}));

const StyledButtonText = styled(Typography) <{ component: 'span' }>`
	display: inline-flex;
	gap: 0.5rem;
	color: ${({ theme }) => theme.palette.primary.main};
	/* background-color: ${({ theme }) => theme.palette.primary.main}; */
  /* color: transparent; */
  /* text-shadow: 2px 2px 3px rgba(0,0,0,0.5);
  background-clip: text;
	font-weight: bold; */
`;

const StyledLink = styled(Link)`
	display: flex;
  width: 100%;
  height: 100%;
`;

export const WhitepaperBlock = forwardRef((_, ref: React.Ref<HTMLDivElement>) => (
	<WhitepaperContainer ref={ref}>
		<SecondaryTitle variant="h3">
			{TEXTS.whitepaper.title}
		</SecondaryTitle>
		<ContentBox>
			<ImageBlock>
				<StyledLink 
					href="https://x2eco.gitbook.io/x2e/welcome/introduction"
					target="_blank"
					rel="noreferrer"
				>
					<ImageButton
						focusRipple
						key={images[0].title}
					>
						<ImageSrc style={{ backgroundImage: `url(${images[0].url})` }} />
						<ImageBackdrop className="MuiImageBackdrop-root" />
						<Image>
							<StyledButtonText
								component="span"
								variant="subtitle1"
								color="inherit"
								sx={{
									position: 'relative',
									p: 4,
									pt: 2,
									pb: '1rem',
								}}
							>
								<img src={WhitepaperText} alt='Whitepaper' height='15px' />
							</StyledButtonText>
						</Image>
					</ImageButton>
				</StyledLink>
			</ImageBlock>
			<PartnersBlock>Partners</PartnersBlock>
		</ContentBox>
	</WhitepaperContainer>
));