import { forwardRef, useRef } from "react";
import { createPortal } from "react-dom";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Typography from "@mui/material/Typography";
import styled from "@mui/material/styles/styled";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import BoltIcon from '@mui/icons-material/Bolt';
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { StyledItem } from "./styled/Item";
import { IconWrapper } from "./styled/IconWrapper";
import { SecondaryTitle } from "./styled/SecondaryTitle";

const responsive = {
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 2,
		slidesToSlide: 2,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1,
	}
};

const CarouselWrapper = styled(StyledItem)`
	width: calc(100% - 6rem);
	margin: auto;
	
	padding: 2rem;
`;

const CarouselTopSection = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 1rem;

	width: calc(100% - 1rem);

	${({ theme }) => theme.breakpoints.down('md')} {
		width: 100%;
		flex-wrap: wrap;
    justify-content: center;
		gap: 1rem;
	}
`;

const StyledCarouselItem = styled(StyledItem)`
	width: calc(100% - 3rem);
	margin: 0;
	border-radius: 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		width: calc(100% - 3rem);
		margin: auto;
	}
`;

const CarouselItemTitleContainer = styled(Box)`
	display: flex;

	align-items: center;

	position: relative;
	padding: 1rem;

	height: calc(100% - 2rem);
`;

const CarouselItemTitle = styled(Typography)`
  display: inline-flex;
  align-items: center;
  margin-left: 1rem;

	color: ${({ theme }) => theme.palette.primary.main};
`;

const CarouselItemDescription = styled(Typography)`
	padding: 1rem;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

const CarouselTitleDivider = styled(Box)`
	position: absolute;
	bottom: 0;
	left: 0;
	background: linear-gradient(90deg, rgba(198, 230, 0, 0) 0%, ${({ theme }) => theme.palette.primary.main} 50%, rgba(198, 230, 0, 0) 100%);
	width: 100%;
	height: 1px;
`;

const StyledIconWrapper = styled(IconWrapper)`
	border-radius: 5px;
	min-width: 40px;
	max-width: 40px;
	min-height: 40px;
	max-height: 40px;
`;

const ActionsContainer = styled(Box)`
	display: flex;
	gap: 0.5rem;
`;

const ActionButton = styled(Button)`
	border-radius: 10px;
	padding: 0.5rem 0.5rem;
`;

type CarouselItemData = {
	title: string,
	description?: string
}

type CarouselProps = { title: string, items: CarouselItemData[] };

type CarouselItemProps = CarouselItemData

const CarouselItem = ({ title, description }: CarouselItemProps) => {

	return (
		<StyledCarouselItem elevation={1}>
			<CarouselItemTitleContainer>
				<StyledIconWrapper>
					<BoltIcon />
				</StyledIconWrapper>
				<CarouselItemTitle variant="h5">
					{title}
				</CarouselItemTitle>
				<CarouselTitleDivider />
			</CarouselItemTitleContainer>
		 	{
				description && (
				<CarouselItemDescription variant="body2">
					{description}
				</CarouselItemDescription>
				)
			}
		</StyledCarouselItem>
	)
}

const CustomButtonGroupAsArrows = (props: any) => {
	const nextDisabled = props.carouselState.currentSlide === props.carouselState.totalItems - props.carouselState.slidesToShow;
	const prevDisabled = !props.carouselState.currentSlide;

	return props.portalNode.current
		&& createPortal(
			<ActionsContainer>
				<ActionButton variant="outlined" disabled={prevDisabled} onClick={props.previous}>
					<WestIcon />
				</ActionButton>
				<ActionButton variant="outlined" disabled={nextDisabled} onClick={props.next}>
					<EastIcon />
				</ActionButton>
			</ActionsContainer>, props.portalNode.current
		)
};

export const CarouselComponent = forwardRef(
	(
		{ title, items }: CarouselProps,
		ref: React.Ref<HTMLDivElement>
	) => {
	const carouselTopSectionRef = useRef<HTMLDivElement>(null);


	return (
		<CarouselWrapper elevation={0} ref={ref}>
			<CarouselTopSection ref={carouselTopSectionRef}>
				<SecondaryTitle variant="h3">{title}</SecondaryTitle>
			</CarouselTopSection>
			<Carousel
				responsive={responsive}
				arrows={false}
				renderButtonGroupOutside
				swipeable
				draggable
				customButtonGroup={<CustomButtonGroupAsArrows portalNode={carouselTopSectionRef} />}
			>
				{
					items.map((item, index) => (
						<CarouselItem
							key={index}
							{...item}
						/>
					))
				}
			</Carousel>
		</CarouselWrapper>
	);
});