import { ChangeEvent, useEffect, useState } from "react";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";
import { useMutation } from "@tanstack/react-query";
import { StyledContainer } from "./styled/Container";
import { StyledTextField } from "./styled/TextField";
import { postTokenClaim } from "../utils/api";
import { useStats } from "../hooks/useStats";
import { SecondaryText } from "./styled/Titles";
import { StyledActionBox } from "./styled/ActionsBox";
import { StyledButton } from "./styled/Button";

export const AddressForm = () => {
	const { stats } = useStats();
	const [walletAddress, setWalletAddress] = useState<string | null>(null);
	const { mutate, isSuccess, isError, isPending, error, data } = useMutation({ mutationFn: postTokenClaim });

	const handleClick = () => {
		if (!stats.tokenAddress) {
			toast.error('You will be able to claim your reward once the token launched');
			return;
		}
		if (!walletAddress) {
			return;
		}
		mutate({ walletAddress });
	}

	const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setWalletAddress(value || null);
	}

	useEffect(() => {
		if (isError) {
			// @ts-ignore 
			const message = error?.response?.data?.error;
			message && toast.error(message);
		}
	}, [error, isError]);

	useEffect(() => {
		if (isSuccess) {
			toast.success('Your wallet address successfully sent');
		}
	}, [isSuccess]);

	const enoughBalance = stats.data && stats.data?.tokenAmount >= stats.data?.minClaimAmount;

	const buttonDisabled = !walletAddress
		|| !enoughBalance
		|| stats.claimed
		|| isSuccess
		|| isPending;
	//UU	
	return (
		<StyledContainer>
			<SecondaryText variant="h5">Paste you wallet address in the field below and click Claim button to get your reward</SecondaryText>
			<StyledActionBox>
				<StyledTextField
					helperText={"Please specify your wallet address"}
					id="wallet-address-text-field"
					label="Wallet address"
					color="info"
					onChange={handleInputChange}
				/>
				<StyledButton
					variant="contained"
					onClick={handleClick}
					disabled={buttonDisabled}>
					Claim
				</StyledButton>
			</StyledActionBox>
			{data?.transactionHash &&
				<Typography color="primary" variant="body2">Transaction hash: {data?.transactionHash}</Typography>
			}
		</StyledContainer>
	)
}