import { useEffect } from "react";
import toast from "react-hot-toast";
import Typography from "@mui/material/Typography";
import { useQuery } from "@tanstack/react-query";

import { StyledContainer } from "./styled/Container";
import { TextToCopyArea } from "./TextToCopyComponent";
import { PostVerification } from "./PostVerification";
import { getTweetText } from "../utils/api";
import { TaskContainer } from "./styled/TaskContainer";
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import { PrimaryTitle } from "./styled/Titles";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

const TweetText = 'Tweet text placeholder';

const AvailableTasksBox = styled(Box)`
	position: relative;
	z-index: 2;
	padding: 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		min-height: calc(100vh - 2rem);
	}
`;
const TopSectionContainer = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem;

	${({ theme }) => theme.breakpoints.down('md')} {
		flex-wrap: wrap-reverse;
	}
`;

const StyledButton = styled(Button)`
	position: relative;
	z-index: 5;
`;

export const AvailableTasks = () => {
	const { data, isError } = useQuery({ queryKey: ['tweetText'], queryFn: getTweetText });
	const navigate = useNavigate();

	const onClick = () => {
		navigate('/');
	};

	useEffect(() => {
		if (isError) {
			toast.error('Error while loading tweet text, please try again later');
		}
	}, [isError]);
	
	return (
		<AvailableTasksBox>
			<TopSectionContainer>
				<SecondaryTitle variant="h4">Task to complete</SecondaryTitle>
				<StyledButton variant="outlined" onClick={onClick}>Go back to Main</StyledButton>
			</TopSectionContainer>
			<TaskContainer elevation={3}>
				<StyledContainer>
					<PrimaryTitle variant="h5">Create Tweet with specified text</PrimaryTitle>
					<TextToCopyArea text={data?.text || TweetText} />
				</StyledContainer>
				<PostVerification />
			</TaskContainer>
		</AvailableTasksBox>
	);
}