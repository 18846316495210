import styled from "@mui/material/styles/styled";
import matrix from '../images/matrix.svg';
import { Box } from '@mui/material';

type GradientBackgroundProps = {
	type?: 'primary' | 'secondary' | 'popup';
}

const Background = styled(Box)<GradientBackgroundProps>`
	position: absolute;
	width: 100%;
	height: 100%;

	left: 0;
	top: 0;

	z-index: 0;
	background: ${({ theme }) => theme.palette.background.default};

	${({ type }) => type === 'secondary' && `
		height: 100vh;
		top: unset;
		bottom: 0;
	`}
`;

const MatrixBackground = styled(Box)`
	position: absolute;
	top: 0;
	left: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-image: url(${matrix});
`;

const Gradient = styled(Box)<GradientBackgroundProps>`
	position: absolute;
	top: 0;
	left: 0;
	
	z-index: 2;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.palette.background.default };
	${({ type }) => type === 'primary' && `
		background: linear-gradient(45deg, rgba(40,43,12,0.9), rgba(19,21,23,1) 30%, rgba(19,21,23,1) 65%, rgba(19,21,23,0.1) 75%),
							linear-gradient(180deg, rgba(19,21,23,1) 10%, rgba(19,21,23,0.7) 35%, rgba(19,21,23,0.1) 70%),
							linear-gradient(270deg, rgba(40,43,12,0.9) 10%, rgba(19,21,23,1) 20%, rgba(19,21,23,1) 50%, rgba(19,21,23,0.1) 20%);
	`}

	${({ type}) => type === 'secondary' && `
		background: radial-gradient(60% 60% at 50% 60%, rgba(8,40,0,0.9), rgba(04,04,04,1) 50%);
	`}

	${({ type }) => type === 'popup' && `
		background: radial-gradient(circle at top, rgba(198,230,0,0.5), rgba(38,70,0,1) 65%, rgba(18,50,0,1) 90%);
	`};
`;

export const GradientBackground = ({ type = 'primary' }: GradientBackgroundProps) => {
	return (
		<Background type={type} >
			<MatrixBackground />
			<Gradient type={type}/>
		</Background>
	)
};