import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useStats } from "./useStats";
import { getTokenStats } from "../utils/api";

export const useUpdateTokenStats = (enabled: boolean) => {
	const stats = useStats();
	const { data, isSuccess, isLoading } = useQuery({ queryKey: ['tokenStats'], queryFn: getTokenStats, enabled });

	useEffect(() => {
		if (data && isSuccess) {
			stats.setStats(data);
			stats.setLoading(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isSuccess]);

	useEffect(() => {
		if (isLoading) {
			stats.setLoading(true);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoading]);
}