import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";

export const IconWrapper = styled(Typography)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 26px;
	width: 26px;
	margin-left: 1px;
	padding: 1px;

	background-color: ${({ theme }) => theme.palette.primary.main};
	border-radius: 5rem;

	& > svg {
		width: 18px;
		height: 18px;
	}
`;