import React from "react";
import { TokenStatsGetResponse as TokenStats } from "../../../shared/contracts/token.stats.get";

interface StatsContextType {
	stats: TokenStats;
	setStats: (stats: TokenStats) => void;
	loading: boolean;
	setLoading: (loading: boolean) => void;
}

export const StatsContext = React.createContext<StatsContextType>(null!);