import styled from "@mui/material/styles/styled";
import Typography from "@mui/material/Typography";
import { StyledContainer } from "./styled/Container";
import { GradientBackground } from "./GradientBackground";
import { SectionContainer } from "./styled/SectionContainer";
import { Actions } from "./Actions";
import { Links } from "./Links";
import { Header } from "./Header";
import BigLogo from "../images/logo_big.png";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import toast from "react-hot-toast";
import { PrimaryTitle, PrimaryTitleWithPointer } from "./styled/Titles";
import { CONTRACT, handleClick } from "../constants/contract";

const MainContainer = styled(StyledContainer)`
	position: relative;
	margin: 1rem 0 3rem 0;
	align-items: center;

	border-radius: 1rem;
	overflow: hidden;

	& > img {
		width: 50%;
		min-width: 350px;
		z-index: 1;
	}
`;


const TEXTS = {
	title: "Changing the game",
	description: "X2E(X to earn) is a first project which will reward the most loyal community members for their marketing effort in spreading the word across the X platform.",
}



export const AboutUsBlock = () => {

	return (
		<MainContainer>
			<GradientBackground />
			<Header />
			<img src={BigLogo} alt="logo" />
			<SectionContainer centered={1}>
				<SecondaryTitle variant="h3" fontWeight="700" color='text.primary'>{TEXTS.title}</SecondaryTitle>
				<Typography variant="body1" color='text.secondary'>{TEXTS.description}</Typography>
			</SectionContainer>
			<Actions />
			<Links />
      <SectionContainer centered={1}>
      <PrimaryTitleWithPointer variant="body1" onClick={handleClick}>
        {/* @ts-ignore */}
        Contract: <SecondaryTitle variant="body1" component="span" style={{fontSize: '1.25rem'}}>{CONTRACT}</SecondaryTitle>
      </PrimaryTitleWithPointer>
      </SectionContainer>
		</MainContainer>
	);
}