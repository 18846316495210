import axios from "axios";
import { config } from "../constants/apiConfig";
import { Response as SignInButtonLinkResponse } from "../../../shared/contracts/auth.link.get";
import { Request as PostCallbackRequest, Response as PostCallbackResponse } from "../../../shared/contracts/auth.callback.post";
import { TokenClaimPostRequest, TokenClaimPostResponse } from "../../../shared/contracts/token.claim.post";
import { TokenStatsGetResponse } from "../../../shared/contracts/token.stats.get";
import { TweetLinkPostRequest, TweetLinkPostResponse } from "../../../shared/contracts/tweet.link.post";
import { TweetTextGetResponse } from "../../../shared/contracts/tweet.text.get";
import { TgPostRequest, TgPostResponse } from "../../../shared/contracts/tg.post";

const instance = axios.create({
  baseURL: config.url.API_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  }
});

instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('bearerToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(response => response, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('bearerToken');
    window.location.reload();
  }
  return Promise.reject(error);
})

export const getSignInLink = () => instance.get<SignInButtonLinkResponse>('/auth/link').then((res) => res.data);

export const postCallback =
  (req: PostCallbackRequest) => instance.post<PostCallbackResponse>('/auth/callback', req).then((res) => res.data);


export const getTokenStats = () => instance.get<TokenStatsGetResponse>('/token/stats').then((res) => res.data);
export const postTokenClaim = (req: TokenClaimPostRequest) => instance.post<TokenClaimPostResponse>('/token/claim', req).then((res) => res.data);

export const getTweetText = () => instance.get<TweetTextGetResponse>('/tweet/text').then((res) => res.data);
export const postTweetLink = (req: TweetLinkPostRequest) => instance.post<TweetLinkPostResponse>('/tweet/link', req);

export const postTgUsername = (req: TgPostRequest) => instance.post<TgPostResponse>('/tg', req);