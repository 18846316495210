import { Route, Routes } from "react-router-dom";

import { MainPage } from "./pages/Main";
import { TasksPage } from "./pages/Tasks";
import { Layout } from "./components/Layout";
import { RequireAuth } from "./components/RequireAuth";
import { AuthProvider } from "./providers/authProvider";
import { StatsProvider } from "./providers/statsProvider";
import { Toaster } from "react-hot-toast";


const App = () => {  
  return (
    <AuthProvider>
      <StatsProvider>
        <Toaster toastOptions={{
          style: {
            borderRadius: '1rem',
            background: '#393A3B',
            color: '#C7E600',
          }
        }} />
        <Routes>
          <Route element={<Layout />}>
            <Route path="/" element={<MainPage />} />
            <Route path="/tasks" element={
              <RequireAuth>
                <TasksPage />
              </RequireAuth>
            } />
          </Route>
        </Routes>
      </StatsProvider>
    </AuthProvider>
  )
}

export default App;
