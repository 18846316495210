import Container from "@mui/material/Container";
import { styled } from "@mui/material";

export const StyledContainer = styled(Container)`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	z-index: 1;
`;
