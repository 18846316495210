import Paper from "@mui/material/Paper";
import { styled } from '@mui/material/styles';

export const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: '0.5rem 1rem',
	margin: '0 2rem',
	textAlign: 'center',
	color: theme.palette.text.secondary,
	borderRadius: '2rem',
	height: 'calc(100% - 1rem)',
}));