import TextareaAutosize from "@mui/material/TextareaAutosize";
import styled from "@mui/material/styles/styled";

export const StyledTextarea = styled(TextareaAutosize)`
	width: 100%;
  padding: 1rem 2rem 1rem 1rem;
	font-size: 16px;
	
	background: #040404;
  border-radius: 3px;

	border-color: ${({ theme }) => theme.palette.primary.dark};
	color: ${({ theme }) => theme.palette.text.secondary};
`;