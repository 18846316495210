import toast from "react-hot-toast";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "../contexts/AuthContext";
import { postCallback } from "../utils/api";
import { useQueryParams } from "../hooks/useQueryParams";
import { useLocalStorage } from "../hooks/useLocalStorage";

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { setLocalStorageItem, removeLocalStorageItem, getLocalStorageItem } = useLocalStorage();
  const token = getLocalStorageItem('bearerToken');
  const [isAuthentificated, setIsAuthentificated] = useState(!!token);
  const { mutate, isSuccess, isError, isPending, data } = useMutation({ mutationFn: postCallback });
  const queryParams = useQueryParams();
  const navigate = useNavigate();

  const signin = useCallback((token: string) => {
    setLocalStorageItem('bearerToken', token);
    setIsAuthentificated(true);
    navigate("/tasks");
  }, [navigate, setLocalStorageItem]);

  const signout = useCallback(() => {
    removeLocalStorageItem('bearerToken');
    setIsAuthentificated(false);
    navigate("/");
  }, [navigate, removeLocalStorageItem]);

  useEffect(() => {
    if (isSuccess && data?.bearerToken && !isAuthentificated) {
      signin(data.bearerToken);
    }
  }, [data?.bearerToken, isAuthentificated, isSuccess, signin]);

  useEffect(() => {
    if (isError) {
      toast.error('Authentification error, please try again later');
      signout();
    }
  }, [isError, signout]);

  useEffect(() => {
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const bearerToken = getLocalStorageItem('bearerToken');
    if (!bearerToken && code && state && !isSuccess && !isError && !isPending) {
      mutate({ code, state });
    }
    // if (window.document.referrer === 'https://twitter.com' && code && state) {
    //   mutate({ code, state });
    // }
  }, [getLocalStorageItem, isError, isPending, isSuccess, mutate, queryParams]);

  const value = { isAuthentificated, signin, signout };

  return (<AuthContext.Provider value={value}>{children}</AuthContext.Provider>);
}