import { useCallback, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import Button from "@mui/material/Button";

import { getSignInLink } from "../utils/api";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";

type SignInButtonProps = {
	text: string;
}

export const SignInButton: (props: SignInButtonProps) => JSX.Element = ({ text }) => {
	const auth = useAuth();
	const navigate = useNavigate();
	const [link, setLink] = useState<string | null>(null);
	const [buttonClicked, setButtonClicked] = useState(false);
	const { data, isLoading, isRefetching } = useQuery({ 
		queryKey: ['link'], 
		queryFn: getSignInLink,
		enabled: buttonClicked,
	});

	const requestSignInLink = useCallback(() => {
		if (auth.isAuthentificated) {
			console.log('auth: ', auth.isAuthentificated)
			navigate('/tasks');
			return;

		}
		setButtonClicked(true);
	}, [auth.isAuthentificated, navigate]);

	useEffect(() => {
		if (data?.link) {
			setLink(data.link);
		}
	}, [data]);

	useEffect(() => {
		if (link) {
			window.open(link, "_self");
		}
	}, [link])

	return (
		<Button 
			disabled={buttonClicked || isLoading || isRefetching} 
			variant="contained" 
			color="primary"
			onClick={requestSignInLink}
		>
			{!auth.isAuthentificated ? text : 'Go to tasks'}
		</Button>
		);
}