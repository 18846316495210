import Box from "@mui/material/Box";
import styled from "@mui/material/styles/styled";
import SmallLogo from "../images/logo_small.png"
import { Links } from "./Links";
import Button from "@mui/material/Button";
import { useRefs } from "../hooks/useRefs";
import { SecondaryTitle } from "./styled/SecondaryTitle";
import { Link } from "@mui/material";
import { PrimaryTitle } from "./styled/Titles";

const FooterContainer = styled(Box)`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	padding: 1rem 2rem;
	min-height: 75px;
	position: relative;
	z-index: 2;
	background-color: ${({ theme }) => theme.palette.background.paper};

	${({ theme }) => theme.breakpoints.down('md')} {
		justify-content: center;
		gap: 2rem;
	}
`;

const ActionsContainer = styled(Box)`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;

	gap: 1rem;

	& .MuiBox-root {
		padding: 0;
		margin: auto;
		width: unset;
	}
`;

const LogoAndEmailContainer = styled(Box)`
	display: flex;
  justify-content: center;
	flex-flow: row wrap;
	gap: 1rem;
`;

export const Footer = () => {
	const {refs} = useRefs();
	const onClick = () => refs['applyForAirdrop']?.current?.scrollIntoView({ behavior: 'smooth' });

	return (
		<FooterContainer>
      <LogoAndEmailContainer>
					<img src={SmallLogo} alt="logo" style={{ height: "3rem" }} />
					{/* <PrimaryTitle variant="body1">
						Email: <Link href="mailto:neemeyraymih@hotmail.com">neemeyraymih@hotmail.com</Link>
					</PrimaryTitle> */}
				</LogoAndEmailContainer>
			<ActionsContainer>
				<Button variant="contained" onClick={onClick}>
					Contact Us
				</Button>
				<Button variant="contained" onClick={onClick}>
					Apply as a Project
				</Button>
				<Links />
			</ActionsContainer>
		</FooterContainer>
	)
}