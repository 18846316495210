// @ts-nocheck
import styled from "@mui/material/styles/styled";
import Box from "@mui/material/Box";
import { StyledContainer } from "./styled/Container"
import { useStats } from "../hooks/useStats";
import { PrimaryDarkTitle, PrimaryTitle, SecondaryText } from "./styled/Titles";

const StatsContainer = styled(StyledContainer)`
	padding: 1rem 1rem 2rem 1rem;
	position: relative;
`;

const TaskStatsContainer = styled(Box)`
`;

export const TokenInfo = () => {
	const { stats: { data, taskDone } } = useStats();

	return (
		<StatsContainer>
			<PrimaryTitle variant="h5">Quick info about the task</PrimaryTitle>
			<PrimaryDarkTitle variant="body1">
				Task status: <SecondaryText variant="body1" component="span">{taskDone ? 'Done' : 'Not done'}</SecondaryText>
			</PrimaryDarkTitle>
			{taskDone
				? (
					<TaskStatsContainer>
						<PrimaryDarkTitle variant="body1">
							Verified at: 
							<SecondaryText variant="body1" component="span">
								{data?.doneAt ? new Date(data?.doneAt).toLocaleString('en-GB') : 'Not verified yet'}
							</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Views: <SecondaryText variant="body1" component="span">{data?.views || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Likes: <SecondaryText variant="body1" component="span">{data?.likes || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Retweets: <SecondaryText variant="body1" component="span">{data?.retweets || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Replies: <SecondaryText variant="body1" component="span">{data?.replies || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Token amount: <SecondaryText variant="body1" component="span">{data?.tokenAmount || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Min claim amount: <SecondaryText variant="body1" component="span">{data?.minClaimAmount || 0}</SecondaryText>
						</PrimaryDarkTitle>
						<PrimaryDarkTitle variant="body1">
							Task status: <SecondaryText variant="body1" component="span">{taskDone ? 'Done' : 'Not done'}</SecondaryText>
						</PrimaryDarkTitle>
					</TaskStatsContainer>
				)
				: null}
		</StatsContainer>
	)
}