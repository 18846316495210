import styled from "@mui/material/styles/styled";
import { CopyToClipboardButton } from "./CopyToClipboardButton";
import { StyledTextarea } from "./styled/Textarea";
import { StyledBox } from "./styled/Box";

const TextToCopyAreaBox = styled(StyledBox)`
	width: 100%;
	flex-direction: row;
	justify-content: flex-start;

	position: relative;
	margin: 1rem 0 2rem;


	padding: 0;
`;

type TextToCopyAreaProps = {
	text: string;
}
export const TextToCopyArea = ({ text }: TextToCopyAreaProps) => {
	return (
		<TextToCopyAreaBox>
			<StyledTextarea value={text} disabled />
			<CopyToClipboardButton text={text} />
		</TextToCopyAreaBox>
	);
}